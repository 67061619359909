<!--
Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
Copyright (C) 2024 HTWKalender support@htwkalender.de

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->

<script lang="ts" setup>
import { computed, ComputedRef, Ref, ref, watch } from "vue";
import {
  fetchCourseBySemester,
  fetchModulesByCourseAndSemester,
} from "@/api/fetchCourse";
import DynamicPage from "@/view/DynamicPage.vue";
import ModuleSelection from "@/components/ModuleSelection.vue";
import { Module } from "@/model/module.ts";
import { useI18n } from "vue-i18n";
import moduleStore from "@/store/moduleStore";
import { router } from "@/main";

async function getModules() {
  modules.value = await fetchModulesByCourseAndSemester(
    selectedCourse.value.name,
    selectedSemester.value.value,
  );
}

async function getCourses() {
  courses.value = await fetchCourseBySemester(
    selectedSemester.value.value,
  ).then((data) => {
    return data.map((course) => {
      return { name: course };
    });
  });

  // replace selected course if it is not available in the new courses list
  if (
    selectedCourse.value.name !== "" &&
    !courses.value.some((course) => course.name === selectedCourse.value.name)
  ) {
    selectedCourse.value = { name: "" };
  }
  // if course is selected, get the modules for the selected course
  if (selectedCourse.value.name !== "") {
    await getModules();
  }
}

const store = moduleStore();
const { t } = useI18n({ useScope: "global" });

const selectedCourse: Ref<{ name: string }> = ref({ name: "" });
const courses: Ref<{ name: string }[]> = ref([]);
const modules: Ref<Module[]> = ref([]);

const semesters: ComputedRef<{ name: string; value: string }[]> = computed(
  () => [
    { name: t("courseSelection.winterSemester"), value: "ws" },
    { name: t("courseSelection.summerSemester"), value: "ss" },
  ],
);

// return current semester summer form march to august and winter from september to february
const currentSemester: { name: string; value: string } =
  new Date().getMonth() >= 2 && new Date().getMonth() <= 7
    ? semesters.value[1]
    : semesters.value[0];

const selectedSemester: Ref<{ name: string; value: string }> =
  ref(currentSemester);

//if semesters get changed, refresh the selected semester ref with a watcher
watch(
  semesters,
  (newValue: { name: string; value: string }[]) =>
    (selectedSemester.value =
      newValue[selectedSemester.value.value === "ws" ? 0 : 1]),
);

// init the courses dropdown with the first semester that is default selected
getCourses();
</script>

<template>
  <DynamicPage
    :hide-content="selectedCourse.name === ''"
    :headline="t('courseSelection.headline')"
    :sub-title="t('courseSelection.subTitle')"
    icon="pi pi-calendar"
    :button="{
      label: t('courseSelection.nextStep'),
      icon: 'pi pi-arrow-right',
      disabled: store.isEmpty(),
      onClick: () => router.push('/additional-modules'),
    }"
  >
    <template #selection="{ flexSpecs }">
      <Dropdown
        v-model="selectedCourse"
        :options="courses"
        :class="flexSpecs"
        filter
        option-label="name"
        :placeholder="t('courseSelection.courseDropDown')"
        :empty-message="t('courseSelection.noCoursesAvailable')"
        :auto-filter-focus="true"
        @change="getModules()"
      ></Dropdown>
      <Dropdown
        v-model="selectedSemester"
        :options="semesters"
        :class="flexSpecs"
        option-label="name"
        :placeholder="t('courseSelection.semesterDropDown')"
        @change="getCourses()"
      ></Dropdown>
    </template>
    <template #content>
      <ModuleSelection
        :modules="modules"
        :selected-course="selectedCourse.name"
      />
    </template>
  </DynamicPage>
</template>
